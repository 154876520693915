export const constitutionClassify = (sheet) => {
  const idxs = [
    [3, 5, 8, 9, 15, 16, 17, 22, 23, 24, 28, 31, 33, 36, 37, 41, 42, 43, 44],
    [2, 4, 8, 10, 11, 16, 18, 20, 23, 26, 29, 30, 31, 32, 35, 37, 38, 39, 40],
    [1, 4, 5, 6, 7, 12, 13, 14, 16, 17, 19, 20, 21, 25, 27, 34],
  ];
  const tresholds = [31, 30, 27];
  let constitution = [];
  for (let i = 0; i < idxs.length; i++) {
    let sum = 0;
    for (let j = 0; j < idxs[i].length; j++) {
      sum += sheet[idxs[i][j] - 1].score;
    }
    if (sum >= tresholds[i]) {
      constitution.push(i);
    }
  }
  if (constitution.length === 0) {
    constitution.push(3);
  }
  return constitution;
};
