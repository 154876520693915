<template>
  <div class="questionnaire">
    <v-container fluid>
      <v-card>
        <v-card-title>問題{{ questionNo }}</v-card-title>
        <v-card-text class="text-h5 text-center pb-0">
          {{ allAns[bcqTree.probIdx].question }}
          <v-rating
            color="primary"
            background-color="teal  lighten-2"
            length="5"
            hover
            :value="allAns[bcqTree.probIdx].score"
            v-model="allAns[bcqTree.probIdx].score"
          ></v-rating>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn v-if="questionPath.length > 0" color="error" @click="previous">上一題</v-btn>
          <v-spacer />
          <v-btn
            color="info"
            @click="confirm"
            :disabled="openResult || allAns[bcqTree.probIdx].score === 0"
          >
            {{ nextBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-fab-transition>
        <v-btn dark bottom fixed left fab color="primary" to="/home">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>
    <v-dialog v-model="openResult" persistent>
      <v-card>
        <v-toolbar color="primary">
          <v-toolbar-title>{{ resTitle }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeResult()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-card
            v-for="(constitution_idx, i) in constitutions"
            :key="`constitution${i}`"
            :color="constitutionInfo[constitution_idx].color"
            class="mt-1"
          >
            <v-card-title class="">
              {{ constitutionInfo[constitution_idx].name }}
            </v-card-title>
            <v-card-subtitle class="md-0" light>
              <v-container fluid>
                <v-row dense>
                  <span class="grey--text text--darken-4">你是不是可能有以下症狀：</span>
                  <span class="grey--text text--lighten-3">
                    {{ constitutionInfo[constitution_idx].features }}
                  </span>
                </v-row>
                <v-row dense>
                  <span class="grey--text text--darken-4">建議：</span>
                  <span class="grey--text text--lighten-3">
                    {{ constitutionInfo[constitution_idx].suggestions }}
                  </span>
                </v-row>
              </v-container>
            </v-card-subtitle>
          </v-card>
        </v-container>
        <v-card-actions>
          <v-btn class="success" v-if="!isFinalRes" @click="toFinishAll()">繼續填寫</v-btn>
          <v-spacer />
          <v-btn class="secondary" @click="closeResult()">關閉</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="keepFinishing">
      <v-card>
        <v-toolbar color="primary">
          <v-toolbar-title>繼續完成問卷</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeLeftQuestion()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col v-for="(q, i) in leftQuestion" :key="`leftQuestion_${i}`" cols="12">
              <v-card>
                <v-card-title>問題{{ questionNo + i }}</v-card-title>
                <v-card-text class="text-h5 text-center pb-0">
                  {{ q.question }}
                  <v-rating
                    color="primary"
                    background-color="teal  lighten-2"
                    length="5"
                    hover
                    :value="q.score"
                    v-model="q.score"
                  ></v-rating>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" :disabled="!finishAll" @click="submitAllAns()">
            送出
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { constitutionClassify } from '@/constitution/analysis.js';
import tree from '@/constitution/tree.json';
import info from '@/constitution/info.json';

export default Vue.component(
  'questionnaire',
  Vue.extend({
    name: 'Questionnaire',
    data: () => ({
      questionNo: 1,
      result: null,
      openResult: false,
      keepFinishing: false,
      finishAll: false,
      isFinalRes: false,
      questionPath: [],
      allAns: [],
      leftQuestion: [],
      bcqTree: tree,
      constitutionInfo: info,
    }),
    watch: {
      bcqTree(val) {
        if (val.probIdx < 0) {
          this.result = val.value.indexOf(Math.max(...val.value));
          this.openResult = true;
          this.initialTree();
        }
      },
      leftQuestion: {
        handler(val) {
          this.finishAll = val.filter((e) => e.score === 0).length === 0 ? true : false;
        },
        deep: true,
      },
    },
    computed: {
      constitutions() {
        return this.result != null ? this.$store.state.class[this.result] : null;
      },
      nextBtn() {
        return this.bcqTree.left.probIdx === -1 || this.bcqTree.right.probIdx === -1
          ? '提交'
          : '下一題';
      },
      resTitle() {
        return this.isFinalRes ? '您有以下體質' : '您可能有以下體質';
      },
    },
    methods: {
      confirm() {
        this.questionPath.push(this.bcqTree);
        this.bcqTree =
          this.allAns[this.bcqTree.probIdx].score <= this.bcqTree.threshold
            ? this.bcqTree.left
            : this.bcqTree.right;
        this.questionNo++;
      },
      previous() {
        this.questionNo--;
        this.allAns[this.bcqTree.probIdx].score = 0;
        this.bcqTree = this.questionPath.pop();
        this.openResult = false;
      },
      toFinishAll() {
        this.openResult = false;
        this.keepFinishing = true;
        for (let i = 0; i < this.allAns.length; i++) {
          if (this.allAns[i].score === 0) {
            let obj = Object.assign({}, this.allAns[i]);
            obj.no = i;
            this.leftQuestion.push(obj);
          }
        }
      },
      submitAllAns() {
        for (let i = 0; i < this.leftQuestion.length; i++) {
          this.allAns[this.leftQuestion[i].no].score = this.leftQuestion[i].score;
        }
        let finalRes = constitutionClassify(this.allAns);
        // console.log(finalRes);
        for (let i = 0; i < this.$store.state.class.length; i++) {
          if (this.$store.state.class[i].length != finalRes.length) {
            continue;
          }
          let found = true;
          for (let j = 0; j < this.$store.state.class[i].length; j++) {
            if (this.$store.state.class[i][j] != finalRes[j]) {
              found = false;
              break;
            }
          }
          if (found) {
            this.result = i;
          }
        }
        this.closeLeftQuestion();
      },
      initialTree() {
        this.bcqTree = tree;
        this.questionPath = [];
      },
      initialAnswer() {
        this.allAns = [];
        for (let i = 0; i < this.$store.state.questions.length; i++) {
          this.allAns.push({ question: this.$store.state.questions[i], score: 0 });
        }
      },
      closeResult() {
        this.openResult = false;
        this.isFinalRes = false;
        this.questionNo = 1;
        this.result = null;
        if (!this.keepFinishing) this.initialAnswer();
      },
      closeLeftQuestion() {
        this.keepFinishing = false;
        this.openResult = this.finishAll;
        this.isFinalRes = this.finishAll;
        this.finishAll = false;
        this.questionNo = 1;
        this.leftQuestion = [];
        this.initialAnswer();
      },
    },
    created() {
      this.initialTree();
      this.initialAnswer();
    },
  }),
);
</script>
